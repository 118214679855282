:root // global wish to share with components

    {
    /* Colors */
    --login-btn: #FFD36C;
    --border-color: #FFD36C;
    --text-color: #FFD36C;
    --mat-bg: #FFD36C;

    --signup-btn: #FF9533;

    --light-grey : #dadada;

    --anchor-color:#FFE5AA;
    --circle-color:#FFE5AA;
    
    --text-white:#FFFFFF;

    --a-tag-text-color-white:#FFFFFF;
    --a-tag-text-color:#FFDA85;
    --a-tag-hover-color:#FFDA85;

    --btn-text-color: #1B2827;
    --btn-filled-hover: #FFE5AA;
    --btn-empty-hover: #696050;
    --table-odd-bg:#1B2827;
    --box-bg: #1b2827;
    --table-new-bg:#1B4B48;
    --btn-hover: #FFD36C33;
    --btn-filled-hover: #FFE5AA;
    --btn-arrow-bg: #2F615D;
    --btn-arrow-hover: #439D97;

    --sidenav-hover: #17302f;

    --table-new-bg:#1B4B48;
    --new-border:#1B4B48;
    --box-bg-menu:#1B4B48;
    --spiner-bg: #1B4B48;
    --spiner-border: #1B2827;
    --completed-badge:#1B4B48;

    --badge-bg-0:#cf142b;
    --badge-color-0:#ffffff;
    --badge-bg-1:#e3ff39;
    --bg-bet:#1d406a;
    --color-text-bet:#1d406a;

    --header-bg: #1B3836;
    --footer-bg: #1B2827;
    --sidenav-bg: #1B2827;
    --msg-box-bg: #1B2827;

    --top-menu-bg: #3c7b77;
    --title-head-bg: #3c7b77;
    --divider-border: #3c7b77;

    --batch-color:#439d97;

    --list-item-bg: #1a4b48;

    --table-head-bg : #2F615D;
    --head-bg : #2F615D;

    --main-bg: #2A5653;
    --main-color: #2A5653;
    --dark-main: #1B3836;
    --popup-divider: #1B3836;

    --dark-table-bg: #234745;

    --soft-dark:#1b4e4a;

    --select-option:#2D5C59;
    // desktop screen size
    --desktop-width: 1248px;
    // desktop screen size
    --mobile-width: 940px;

    //gloabl font-family
    --global-font: 'Lato';

    --global-bg: #253231;

    --verified-bg:#35B668;

    --image-border-gradient: linear-gradient(to left, #E6BC64,#E1D696,#FAF8CA,#F6DA93,#F5C553);
    --image-border:#439D97;
    --image-bg: #3C7B77;
    --badge-bg-2:#39D0FF;
    
    --gradient:linear-gradient(#FF9F47, #E0101D);
    --gradient-level-border:linear-gradient(270deg, #1B2827 0%, #3C7B77 100%) padding-box, linear-gradient( to right , #FFD36C ,#3C7B77) border-box;
    --gradient-border:linear-gradient(180deg, #1B2827 25.5%, #1B2827 100%) padding-box, linear-gradient( to right , #E0101D ,#FF9F47) border-box;
    --card-gradient-border:linear-gradient(180deg, #1a4b48 25.5%, #3c7b77 100%) padding-box, var(--gradient) border-box;
    --days-gradient-border:linear-gradient(180deg, #439D97 25.5%, #439D97 100%) padding-box, linear-gradient( #E0101D ,#FF9F47) border-box;
    --special-day-gradient:linear-gradient(180deg, #1b2827 25.5%, #1b2827 100%) padding-box, var(--gradient) border-box;
    --cta-gradient:linear-gradient(180deg, #1B4B48 25.5%, #1B4B48 100%) padding-box, var(--gradient) border-box;
    --gradient-background: linear-gradient(180deg, #1A4B48 0%, #3C7B77 100%);
    --notify-gradient-border:linear-gradient(180deg, #1B2827 25.5%, #1B2827 100%) padding-box, linear-gradient( to right ,#439D97 ,#3C7B77) border-box;
    --level-gradient-border:linear-gradient(180deg, #1B2827 25.5%, #1B2827 100%) padding-box, linear-gradient( to right ,#FFD36C ,#439D97) border-box;
    --reactivation-border:linear-gradient(270deg, #1B2827 0%, #3C7B77 100%) padding-box, linear-gradient( 90deg,#FFD36C,#FFE5AA ,#FFD36C) border-box;
    --xmas-border:linear-gradient(180deg, #3C7B77 25.5%, #1B2827 100%) padding-box, linear-gradient( to bottom , #F9C839 ,#E0101D) border-box;
    --level-change-border:linear-gradient(270deg, #1B2827 0%, #3C7B77 100%) padding-box, linear-gradient( to right ,#FFD36C ,#439D97) border-box;
    --vip-border:linear-gradient(270deg, #E6BC64 -5.72%, #E1D696 22.44%, #FAF8CA 49%, #F6DA93 77.69%, #F5C553 100%) padding-box, linear-gradient( to bottom , #E6BC64 ,#E6BC64) border-box;

    
    --leve-img-border:linear-gradient(180deg, #3C7B77 100%, #3C7B77 100%) padding-box, linear-gradient( to bottom ,#FFD36C ,#439D97) border-box;
    --unique-img-border:linear-gradient(180deg, #3C7B77 100%, #3C7B77 100%) padding-box, linear-gradient( to bottom ,#439D97 ,#439D97) border-box;
    --default-img-border:linear-gradient(180deg, #1B4B48 100%, #1B4B48 100%) padding-box, linear-gradient( to bottom ,#439D97 ,#439D97) border-box;
    --reactivation-img-border:linear-gradient(180deg, #1B2827 100%, #1B2827 100%) padding-box, linear-gradient( to bottom ,#FFD36C ,#FFD36C) border-box;
    --xmas-img-border:linear-gradient(180deg, #1B2827 100%, #1B2827 100%) padding-box, linear-gradient( to bottom , #E0101D ,#F9C839) border-box;
    // --level-change-img-border:linear-gradient(180deg, #1B2827 100%, #1B2827 100%) padding-box, linear-gradient( to bottom ,#439D97 ,#439D97) border-box;


}